import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const linkedin = this.props.data.linkedin;
    const github = this.props.data.github;
    const name = this.props.data.name;
    const description = this.props.data.description;

    return (
      <div>
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#about" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">

            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#toolbox">
                Toolbox
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#work">
                Works
              </a>
            </li>

          </ul>
        </nav>
      </div>
    );
  }
}

export default Header;
