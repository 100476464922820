import React, { Component } from "react";
import Fade from "react-reveal";

class Footer extends Component {
  render() {
    if (!this.props.data) return null;

    const networks = this.props.data.social.map(function (network) {
      return (
              <a href={network.url} className="button" target="_blank">
                <i className={network.className}></i>
              </a>
      );
    });

    return (
      <footer>
        <div className="row">
          <Fade bottom>
            <div className="twelve columns">
              <ul className="social-links">{networks}</ul>

              <ul className="copyright">
                <li>&copy; Copyright 2023 nnsaori</li>

{/* 
                 <li>
                  Design by{" "}
                  <a className = "copyright" title="Styleshout" href="http://www.styleshout.com/" target="_blank">
                    Styleshout
                  </a>
                </li> 
 */}

              </ul>
            </div>
          </Fade>

          <div id="go-top">
            <a className="smoothscroll" title="Back to Top" href="#about">
              <i className="icon-up-open"></i>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
