import React, { Component } from "react";
import Slide from "react-reveal";
import Zmage from "react-zmage";
import Fade from "react-reveal";

class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const skillmessage = this.props.data.skillmessage;
    const language = this.props.data.education.map(function (education) {
      return (
        <div key={education.school}>
          <h4>{education.school}</h4>
        </div>
      );
    });

    const frameworks = this.props.data.framework.map(function (framework) {
      return (
        <div key={framework.skill}>
          <h4>{framework.skill}</h4>
        </div>
      );
    });

    const skill = this.props.data.skill.map(function (skill) {
      return (
        <div key={skill.skill}>
          <h4>{skill.skill}</h4>
        </div>
      );
    });

    const work = this.props.data.work.map(function (work) {
      let projectImage = "images/portfolio/" + work.image;


      let roll = work.rolls.map(function (roll) {
      return (
        <li>{roll}</li>
      );
    });

      let framework = work.frameworks.map(function (framework) {
      return (
        <li>{framework}</li>
      );
    });

      return (
        <div className="row work">
        <div className="six columns main-col">
        <div key={work.company}>
          <h3>{work.company}</h3>
          <p>{work.title}</p>
          <div className="six columns content-col">
          <p className="info">
            Role
          </p>
          <ui>
            {roll}
          </ui>
          </div>

          <div className="six columns content-col">
          <p className="info">
            Framework
          </p>
          <ui>
            {framework}
          </ui>
          </div>
        </div>
        </div>
        <div className="six columns main-col"> 
          <div className="item-wrap">
            <Zmage alt={work.title} src={projectImage} />
          </div>
        </div>
        </div>
      );
    });

    return (
      <section id="toolbox">
        <Slide left duration={1300}>
          <div className="row education">
            <div className="header-col">
              <h1>
                <span>ToolBox</span>
              </h1>
            </div>

            <div className="four columns">
              <p className="info">
                <em className="date">Language</em>
              </p>
              {language}
            </div>

            <div className="four columns">
              <p className="info">
                <em className="date">Frameworks</em>
              </p>
              {frameworks}
            </div>

            <div className="four columns">
              <p className="info">
                <em className="date">Skills</em>
              </p>
              {skill}
            </div>           

          </div>
        </Slide>
      </section>
    );
  }
}

export default Resume;
